@import "@/scss/variables.scss";


header{
  padding-top: 2.1875rem;
  position: relative;
  z-index: 1;
  @include media-breakpoint-down(lg){
      padding-top: 0.625rem;

  }
}

nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-image{
  width: 100%;
  max-width: 240px;
}
.nav-links{
  display: flex;
  gap: 42px;
  .nav-link {
    color: $links-color;
    font-size: 1rem;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
    &.router-link-exact-active{
      color: #858585;
    }

    &.white_link{
      color: $white-color;
    }
  }
  @include media-breakpoint-down(lg){
    gap: unset;
    justify-content: space-around;
    flex-grow: 1;
  }
  @include media-breakpoint-down(md){
    display: none;
  }
}

.get_started_btn {
  padding: 0 1.5625rem;
  height: 2.5rem;
  vertical-align: middle;
}

.nav_trigger{
  background: none;
  outline: none;
  border: none;
  //position: relative;

  #trigger_background{
    position: absolute;
    z-index: -1;
    top: -0.625rem;
    right: 0;
  }
}

.side-nav {
  //display: none;
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  //background-color: transparent;
  //transition: background-color 0.2s ease-in-out;
  background: rgba(249, 250, 252, 0.9);
  &.open{
    display: block;

  }

  &__wrapper{
    position: relative;
    //width: 100%;
    height: 100%;
     width: 70%;
    margin-left: auto;
  }
  &__header{
    padding-top: 1.5625rem;
    display: flex;
    position: relative;
    z-index: 1;
  }
  &__body{
    //position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;

    &__background{
      position: absolute;
      top: 0;
      left: -15px;
      z-index: 0;
      //width: 100%;
      @include media-breakpoint-up(md){
        left: unset;
        right: 0;
      }
    }

  }
  &__links{
    margin-top: 90px;
    display: inline-flex;
    flex-direction: column;
    gap: 0.625rem;
    position: relative;
  }
  &__link{
    color: $white;
    &:hover{
      text-decoration: underline;
      color: $white;
    }
    &.router-link-exact-active{
      color: $gray-1-color;
    }
  }
  .nav-close_trigger{
    background: none;
    outline: none;
    border: none;
    margin-left: auto;
    margin-right: 1.25rem;
  }
}

.side-nav-enter-active {
  animation: background-color-in .5s;
}
.side-nav-leave-active {
  animation: background-color-in .5s reverse;
}

//.side-nav-enter-active .side-nav__body__background{
//  animation: morph-in .5s;
//}
//.side-nav-leave-active .side-nav__body__background{
//  animation: morph-out  .5s;
//}
//
.side-nav-enter-active .side-nav__body__background path{
  animation: morph-in 0.5s linear;
}
.side-nav-leave-active .side-nav__body__background path{
  animation: morph-out 0.5s linear;
}

.side-nav-enter-active .side-nav__links,
.side-nav-enter-active .nav-close_trigger{
  animation: opacity-in .5s cubic-bezier(.32,.53,.57,.21);
}

.side-nav-leave-active .side-nav__links,
.side-nav-leave-active .nav-close_trigger{
  animation: opacity-in  .5s reverse cubic-bezier(.32,.53,.57,.21);
}

@keyframes background-color-in {
  0% {
    background-color: transparent;
  }
  100% {
     background: rgba(249, 250, 252, 0.9);
  }
}

@keyframes opacity-in {
  0% {
    opacity: 0;
  }
  100% {
     opacity: 1;
  }
}

@keyframes morph-out{
  100% {
    transform: translate3d(38%,-84%,0);
  }
  0% {
    transform: translate3d(0,0,0);
  }
}


@keyframes morph-in{
  0% {
    d: path('M170.888 40.2592C172.793 72.5944 174.343 140.221 308.197 139.999H516.5V0H170.129C169.838 12.3238 170.019 25.5109 170.888 40.2592Z');
  }
 5% {
    d: path('M151.085 63.2644C153.413 114.077 155.308 220.347 318.907 219.999H516.5V0H150.157C149.802 19.366 150.023 40.0886 151.085 63.2644Z');
  }
  10%{
    d: path('M119.529 89.1453C122.81 160.745 125.479 310.49 356.006 309.999H598.5L523 0H118.221C117.721 27.2884 118.033 56.4885 119.529 89.1453Z');
  }
  15%{
    d: path('M74.9732 115.026C79.2061 207.413 82.6507 400.632 380.104 399.998H527.5L522 0H73.2856C72.6399 35.2108 73.0424 72.8883 74.9732 115.026Z');
  }
  20%{
    d: path('M-0.000730753 256C5.31148 371.945 33.1106 493.793 406.415 492.998H523L520.501 -9H21.3577C20.5473 35.1896 -2.42386 203.117 -0.000730753 256Z');
  }
  35%{
    d: path('M34.5 172C86.9001 397.001 193 465.5 466.999 495.135H523L520.501 0H21.358C20.5476 43.5854 17.3599 98.4019 34.5 172Z');
  }
  100% {
    d: path('M23.4764 142.384C28.7886 256.744 33.1116 495.919 406.416 495.135H523L520.502 0H21.3584C20.548 43.5854 21.0533 90.2242 23.4764 142.384Z');
  }
  //20%, 40%, 80%{
  //  d: path('M-0.000730753 256C5.31148 371.945 33.1106 493.793 406.415 492.998H523L520.501 -9H21.3577C20.5473 35.1896 -2.42386 203.117 -0.000730753 256Z');
  //}
  //25%, 70%, 95%{
  //  d: path('M34.5 172C86.9001 397.001 193 465.5 466.999 495.135H523L520.501 0H21.358C20.5476 43.5854 17.3599 98.4019 34.5 172Z');
  //}
  //30%, 55%, 75%, 90%,100% {
  //  d: path('M23.4764 142.384C28.7886 256.744 33.1116 495.919 406.416 495.135H523L520.502 0H21.3584C20.548 43.5854 21.0533 90.2242 23.4764 142.384Z');
  //}
}
//
//@keyframes morph-out{
//  100% {
//    d: path('M200.888 40.2592C202.793 72.5944 204.343 140.221 338.197 139.999H519V0H200.129C199.838 12.3238 200.019 25.5109 200.888 40.2592Z');
//  }
//  0% {
//    d: path('M23.4764 142.384C28.7886 256.744 33.1116 495.919 406.416 495.135H523L520.502 0H21.3584C20.548 43.5854 21.0533 90.2242 23.4764 142.384Z');
//  }
//}


