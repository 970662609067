
html{
    //height: 100%;
    background-color: #FFFFFF;
 }
* {
    -webkit-tap-highlight-color: rgba(0,0,0,0); /* make transparent link selection, adjust last value opacity 0 to 1.0 */
}
*, *:before, *:after {
    box-sizing: inherit;
}
body {
    width: 100%;
    //height: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: $main-font;
    color: $main-color;
}
main {
  //height: 100%;
  box-sizing: border-box;
}
.cursor_pointer{
  cursor: pointer;
}
.no_border_radius{
  border-radius: unset;
}

.custom-container {
  //@include make-container(1.5625rem);
  @include make-container(1.25rem);
  max-width: $container_max_width;
}
.hidden{
  visibility: hidden;
}

.btn{
  border-radius: 0.25rem;
  font-family: $second-font;
  padding: 0 1.5625rem;
  font-size: 1rem;
  color: $white-color!important;
  background-color: $btn-color;
  outline: none;
  text-align: center;
  text-decoration: none!important;
  display: flex;
  align-items: center;
  transition: transform 0.2s ease-in-out;
  &:hover{
    transform: translateY(-2px);
    color: $white-color;
  }
  &:active,
  &:focus  {
   background-color: $blue-4-color;
  }
  &.outlined{
    color: $second-color!important;
    background-color: white;
    border: 1px solid $second-color;
     &:hover{
       color: $second-color;
     }
    &:active,
    &:focus {
      background-color: $white-color;
      color: $blue-4-color;
      border: 1px solid $blue-4-color;
    }

  }
}




//
//.container{
//  max-width: $container_max_width;
//  width: 100%;
//  margin-left: auto;
//  margin-right: auto;
//  padding-left: 1rem;
//  padding-right: 1rem;
//  //position: relative;
//
//}
