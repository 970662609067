@import "@/scss/variables.scss";

footer{
  background-color: $dark-section-background-color;
  padding: 50px 0 40px;
  color: $white-color;
}

.logo_container{
  margin-bottom: 3.75rem;
}

.social_links{
  display: flex;
  justify-content: flex-end;

  .social_link{
    height: 44px;
    width: 44px;
    //border-radius: 50%;
    //background-color: $link-light-color;
    display: inline-block;
    text-align: center;
    line-height: 100%;
    &:not(:first-child){
      margin-left: 10px;
    }
    img{
      max-height: 100%;
    }
  }
  @include media-breakpoint-down(md){
     margin-top: 2.1875rem;

  }
  &__bottom{
    margin-top: 3.75rem;
  }
}

.footer_aviapages_logo{
  margin-top: 4px;
  max-height: 25px;
  margin-bottom: 1.25rem;
}
.fs24{
  font-size: 1.5rem;
}
.api_heading{
  margin-bottom: 0.9375rem;
  color: white;
}
.navigation_links{
   @include media-breakpoint-down(md) {
     display: flex;
     justify-content: center;
   }

  &__wrapper{
    display: flex;
    flex-direction: column;
    @include media-breakpoint-down(md) {
      margin-top: 3.75rem;
      //align-items: center;
      display: inline-flex;
      margin-left: auto;
      margin-right: auto;
    }

  }

  .footer_nav_link{
    margin-bottom: 0.9375rem;
  }
}
.footer_nav_link{
  color: $link-light-color;
}
.description__wrapper{
  @include media-breakpoint-down(md){
    margin-top: 3.75rem;
  }
}
.description{
  font-size: 0.875rem;
  line-height: 1.4375;
}
.signup_newsletter{
  margin-top: 3.75rem;
  &__label{
    color: white;
    font-size: 1.125rem;
    font-weight: normal;
    margin-bottom: 0;
  }
  @include media-breakpoint-down(md){
    margin-top: 2rem;
  }
  &__wrapper{
    position: relative;
    margin-top: 0.675rem;
    display: inline-block;
    @include media-breakpoint-down(md){
      width: 100%;
    }
  }
  &__input{
    border-radius: 2px;
    background-color: $third-color;
    height: 2.125rem;
    padding: 0 0.625rem;
    border: none;
    outline: none;
    color: white;
    &::placeholder{
      color: $blue-6-color;
    }
    width: 255px;
    @include media-breakpoint-down(md){
      width: 100%;
    }
  }
  &__submit_button{
    position: absolute;
    top: 50%;
    transform:translateY(-50%);
    right: 0.625rem;
    background: transparent;
    border: none;
    &:hover{
      margin-top: -2px;
    }

  }
  &__notification{
    position: absolute;
    bottom: -100%;
    left: 10px;
    right: 0;
    width: 100%;
  }
}
.copyright_section{

  @include media-breakpoint-down(md){
    margin-top: 3.75rem;
    display: flex;
    flex-direction: column-reverse;

    .copyright{
      margin-top: 2.1875rem;
      text-align: center;
    }
  }

}
.vertical_devider{
  background-color:$third-color;
  width: 1px;
  height: 24px;
}
.privacy_links{
  justify-content: flex-end;
  gap: 1.5625rem;
  @include media-breakpoint-down(md){
    justify-content: center;
  }
}


